@import 'style';

.blocks {
	position: relative;
	margin-top: 50px;
}

.block {
	& + & {
		margin-top: 50px;
	}
}
